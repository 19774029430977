* {
  min-width: 0;
}
.App {
  text-align: center;
  padding-top: 56px; /* Add padding for fixed navbar */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  margin: auto 1px;
}

#chat td:first-child,
#chat td:last-child {
  padding: 25px;
  vertical-align: top; /* Adjust vertical alignment as needed */
}
#chat tr {
  border-color: #fff;
  border-style: solid;
  border-width: 3px;
}
/*#chat tbody tr.mine td.timestamp {
  text-align: left;
}
#chat tbody tr.theirs td.timestamp {
  text-align: right;
}*/
#chat tbody tr.mine td:last-child {
  background-color: #99ff99;
  text-align: left;
}
#chat tbody tr.theirs td:first-child {
  background-color: teal;
  text-align: right;
}
#chat img {
  max-width: 100%;
  height: auto;
}
#chat td {
  border-radius: 15px;
}
#chat td div.timestamp {
  /*background-color: #fff;*/
  font-size: 8px;
  vertical-align: bottom;
  /*font-size: smaller;*/
  font-weight: bold;
  position: relative;
  bottom: -25px;
  right: -20px;
  margin: 1px 0 0 1px; /* Optional: Add some margin for spacing */
  text-align: right;
}
.led-light {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.led-success {
  background-color: #28a745; /* Bootstrap success color */
}

.led-danger {
  background-color: #dc3545; /* Bootstrap danger color */
}
.navbar-brand {
  width: 185px;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hand-pointer {
  cursor: pointer;
}
